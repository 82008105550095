import Box from "@mui/material/Box";
import * as React from "react";
import BlogLayout from "src/components/BlogLayout";
import Paragraph from "src/components/Paragraph";
import wendyImg from "src/images/community/wendy-next-chapter.jpg";
export default function NextChapterForEOCPPage() {
  return (
    <BlogLayout slug="next-chapter-for-eocp">
      <Paragraph>
        <strong>Dear Friend:&nbsp;</strong>
      </Paragraph>
      <Paragraph>
        Joining EOCP as Interim Executive Director&mdash;and learning about its
        history, strengths, and vision&mdash;has been a bittersweet gift. I am
        moved by the heartfelt stories of staff, colleagues, and partners who
        collaborated with EOCP&rsquo;s longtime leader, Wendy Jackson, across
        her 26 year tenure, and the{" "}
        <strong>
          reflection and celebration they offer about the incredible impact of
          Wendy&rsquo;s leadership
        </strong>
        . One thing is clear: Wendy&rsquo;s authenticity, dedication, and ethic
        of holistic care for EOCP&rsquo;s clients has shaped all aspects of our
        work today.&nbsp;
      </Paragraph>
      <Paragraph>
        Today, EOCP is more than just a critical first stop on clients&rsquo;
        road to finding permanent housing. It&rsquo;s also an anchor for{" "}
        <strong>community well-being and healing. </strong>Our evolution from a
        single struggling shelter in Oakland to the comprehensive services we
        offer today stemmed from{" "}
        <strong>the clear, ambitious vision that Wendy brought to EOCP</strong>.
      </Paragraph>
      <Box
        sx={{
          backgroundColor: "green.50",
        }}
        p={3}
        pb={1}
        my={3}
      >
        <Paragraph>
          <em>
            David Modersbach from Alameda County Health Care for the Homeless
            remembered how Wendy emphasized a focus on community well-being
            during a make-or-break time of EOCP&rsquo;s history:&nbsp;
          </em>
          <br />
          <br />
          <img
            src={wendyImg}
            alt=""
            width="206"
            height="150"
            style={{ float: "right", margin: "0 0 10px 20px" }}
          />
          I was a young outreach worker in 1996 when Wendy welcomed me to EOCP
          into their glorious shelter in the decrepit&nbsp;old Safeway building,
          and we worked together to prevent infectious diseases among residents.
          Through{" "}
          <strong>
            constantly&nbsp;listening, learning, and improving programs
          </strong>
          , Wendy showed with her eyes, mind, and heart how to make the shelter
          a&nbsp;healthy place for both residents and staff.
        </Paragraph>
      </Box>
      <Paragraph>
        This aspect of Wendy&rsquo;s leadership has become a hallmark of the
        &ldquo;EOCP way&rdquo;: always listen and learn as we{" "}
        <strong>support our clients on their healing journeys</strong>. Wendy
        knew that without genuinely personalized care, we risk perpetuating
        systemic racism and poverty. She held the bigger picture of what
        transformation can happen when we emphasize our care for clients&rsquo;
        personal dignity, self-worth, and good health.&nbsp;
      </Paragraph>
      <Paragraph>
        Wendy&rsquo;s approach set a model for the power of Black-led community
        agencies and homegrown solutions to tackling homelessness &mdash; and
        how to drive forward a larger goal of{" "}
        <strong>
          healing the Black community from constant racial and economic
          inequities.
        </strong>{" "}
        I am struck by how EOCP&rsquo;s holistic approach to community healing
        exists today because of Wendy &mdash;&nbsp;and how we must sustain and
        uplift this focus through a time of leadership change and loss. As we
        head towards the end of 2022, please join us in
        <strong>
          {" "}
          remembering and recognizing Wendy and her tremendous impact by{" "}
          <a
            href="https://www.paypal.com/donate/?hosted_button_id=G93HRCX7NCCL6"
            target="_blank"
            rel="noreferrer"
          >
            donating to the Wendy Jackson Legacy Fund today
          </a>
          .
        </strong>
        <br />
        &nbsp;
      </Paragraph>
      <Paragraph>
        I am grateful for the chance to honor Wendy&rsquo;s legacy as we step
        forward into the next chapter.{" "}
        <strong>Your donation to the Wendy Jackson Legacy Fund matters</strong>
        .&nbsp; &nbsp;
      </Paragraph>
      <Paragraph>
        <br />
        With warmth and appreciation,&nbsp;
      </Paragraph>
      <Paragraph>
        <strong>Shari P. Wooldridge</strong>
        <br />
        <em>Interim Executive Director</em>
      </Paragraph>{" "}
    </BlogLayout>
  );
}
